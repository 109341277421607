import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  eventList: [],
  parishList: [],
  participantList: [],
  paymentList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUserSuccess(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getParishListSuccess(state, action) {
      state.isLoading = false;
      state.parishList = action.payload;
    },

    getParticipantListSuccess(state, action) {
      state.isLoading = false;
      state.participantList = action.payload;
    },

    getPaymentListSuccess(state, action) {
      state.isLoading = false;
      state.paymentList = action.payload;
    },

    getEventListSuccess(state, action) {
      state.isLoading = false;
      state.eventList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions; // , deleteUser

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList(activeUser) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/participants/list',
        {
          role: activeUser.role,
          ID: activeUser.ID
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipantList(activeUser) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/participants/list',
        {
          role: activeUser.role,
          ID: activeUser.ID
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getParticipantListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserPaymentList(activeUser) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/family/payments',
        {
          role: activeUser.role,
          ID: activeUser.ID || localStorage.userEmail
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getPaymentListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFamilyList(parentAccountID = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/participants/family_member_list',
        {
          ID: parentAccountID && parentAccountID !== '' ? parentAccountID : localStorage.userEmail
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      console.log(parentAccountID);
      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFamilyAccountList(activeUser) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/family/list',
        {
          role: activeUser.role,
          ID: activeUser.ID || localStorage.userEmail
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/parish/delete',
        {
          ID: userId
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------

export function getParishList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/parish/parishlist', '{}', {
        headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') }
      });
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getParishListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/events/list', '{}', {
        headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') }
      });
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getEventListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParticipantEventList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/participantevents/list', '{}', {
        headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') }
      });
      // const users = [...Array(24)].map((_, index) => response.data.response[0]);
      // console.log(response, users);
      dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventConfigList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/get-config/IPSF-EVENT', '{"ID":"IPSF-EVENT"}', {
        headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') }
      });
      const eve = response.data.response.details;
      const group = [];
      const eventgroup = [];
      const event = [];
      Object.keys(eve).map((value, key) => {
        console.log('nameval', value, eve[value]);
        group.push(eve[value]);
        Object.keys(eve[value]).map((groupkey) => {
          console.log('groupname', groupkey, eve[value][groupkey]);
          return 1;
        });
        return 1;
      });
      console.log(group);
      dispatch(slice.actions.getUserListSuccess(group));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEvent(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/events/delete',
        {
          ID: userId
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      window.location.reload();
      // dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteParticipantEvent(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/participantevents/delete',
        {
          ID: userId
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      window.location.reload();
      // dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStreaming(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/live-streaming/delete',
        {
          ID: userId
        },
        { headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') } }
      );
      window.location.reload();
      // dispatch(slice.actions.getUserListSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
