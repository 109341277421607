import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import NoLogoLayout from '../layouts/NoLogoLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register/-protected',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'register-success', element: <RegisterSuccess /> }
      ]
    },

    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'analytics',
          element: <GeneralAnalytics />
        },
        {
          path: 'e-commerce',
          children: [
            { path: '', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        { path: 'reports', element: <Reports /> },
        {
          path: 'check-in',
          element: (
            <RoleBasedGuard accessibleRoles={['Admin', 'SuperAdmin', 'CheckInCoordinator']}>
              <CheckInPage />
            </RoleBasedGuard>
          )
        },
        {
          path: 'user',
          children: [
            { path: '', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':display/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'payment', element: <ParticipantRegistrationPayment /> },
            { path: 'payments', element: <ParticipantPayments /> },
            { path: 'payment-transactions', element: <PaymentTransactions /> }
          ]
        },
        {
          path: 'family',
          // element: <RoleBasedGuard />,
          // accessibleRoles: ['admins', 'leader'],
          children: [
            { path: 'list', element: <FamilyList /> },
            { path: 'new', element: <FamilyCreate /> },
            { path: ':display/edit', element: <FamilyCreate /> }
          ]
        },
        {
          path: 'participant',
          // element: <RoleBasedGuard />,
          // accessibleRoles: ['admins', 'leader'],
          children: [
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':display/edit', element: <UserCreate /> }
          ]
        },
        {
          path: 'eventoperations',
          // accessibleRoles: ['admins', 'leader'],
          // accessibleRoles: ['Admin', 'SuperAdmin', 'ScoringCoordinator', 'SchedulingScoringCoordinator'],
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', 'SuperAdmin', 'ScoringCoordinator', 'SchedulingScoringCoordinator']}
                >
                  <EventOperationsList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'scheduleoperations',
          // element: <RoleBasedGuard />,
          // accessibleRoles: ['admins', 'leader'],
          accessibleRoles: ['Admin', 'SuperAdmin', 'SchedulingCoordinator', 'SchedulingScoringCoordinator'],
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', 'SuperAdmin', 'SchedulingCoordinator', 'SchedulingScoringCoordinator']}
                >
                  <ScheduleEditList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'livestreaming',
          // element: <RoleBasedGuard />,
          // accessibleRoles: ['admins', 'leader'],
          children: [{ path: 'list', element: <LiveStreamingList /> }]
        },
        {
          path: 'parish',
          // element: <RoleBasedGuard />,
          // accessibleRoles: ['Admin', 'SuperAdmin'],
          children: [
            { path: 'list', element: <ParishList /> },
            {
              path: 'participant-list',
              element: (
                <RoleBasedGuard accessibleRoles={['Parish', 'ParishCoordinator']}>
                  <ParishParticipantList />
                </RoleBasedGuard>
              )
            },
            { path: 'new', element: <ParishCreate /> },
            { path: ':display/edit', element: <ParishCreate /> }
          ]
        },
        {
          path: 'familymember',
          children: [
            { path: 'list', element: <FamilyMemberList /> },
            { path: 'new', element: <FamilyMemberCreate /> },
            { path: ':display/edit', element: <FamilyMemberCreate /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { path: '', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'event',
          children: [
            { path: 'list', element: <EventList /> },
            { path: 'new', element: <EventCreate /> },
            { path: 'list', element: <EventCreate /> },
            { path: ':display/edit', element: <EventCreate /> }
          ]
        },
        {
          path: 'familyaccountschedule',
          children: [{ path: 'list', element: <FamilyAccountSchedule /> }]
        },
        {
          path: 'eventconfig',
          children: [
            { path: 'list', element: <EventConfigList /> },
            { path: 'new', element: <EventConfigCreate /> },
            { path: 'list', element: <EventConfigCreate /> },
            { path: ':display/edit', element: <EventConfigCreate /> }
          ]
        },
        {
          path: 'eventschedule',
          children: [{ path: 'new', element: <EventScheduleCreate /> }]
        },
        {
          path: 'participantevent',
          children: [
            { path: 'list', element: <ParticipantEventList /> },
            { path: 'reg', element: <ParticipantEventReg /> },
            { path: 'final-list', element: <ParticipantRegList /> },
            { path: 'evereg-list', element: <RegParticipantList /> },
            { path: 'new', element: <ParticipantEventCreate /> },
            { path: 'list', element: <ParticipantEventCreate /> },
            { path: ':display/edit', element: <ParticipantEventCreate /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <NoLogoLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        // { path: '404', element: <NotFound /> },
        // { path: 'terms', element: <Terms /> },
        { path: 'ipsfupcomingevents', element: <TvScreens /> },
        { path: 'delay', element: <DelayList /> },
        { path: 'allevents', element: <AllEvents /> },
        { path: 'leaderboard', element: <LeaderBoard /> },
        { path: 'ipsfleaderboard', element: <TvLeaderBoard /> },
        { path: 'leaderboardlist', element: <LeaderBoardList /> },
        { path: 'upcomingeventlist', element: <UpcomingEventslist /> },
        { path: 'winnerslist', element: <WinnersList /> },
        { path: 'progressingeventlist', element: <ProgressingEventList /> },
        { path: 'upcomingevents', element: <UpcomingEvents /> },
        { path: 'progressingevents', element: <ProgressingEvents /> },
        { path: 'tvprogressingevents', element: <TvProgressingEvents /> },
        { path: 'ipsfwinners', element: <TvWinner /> },
        { path: 'winnersexp', element: <WinnerExp /> },
        { path: 'winners', element: <Winners /> }
        // { path: 'passwordemail', element: <PasswordEmail /> },
        // { path: 'requestpasswordemail', element: <PasswordReq /> },
        // { path: 'confirmpassword', element: <ConfirmPassword /> },
        // { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    {
      path: '*',
      element: <LogoOnlyLayout logo={false} />,
      children: [{ path: 'winnerlistexp', element: <WinnerListExp /> }]
    },

    {
      path: '*',
      element: <LogoOnlyLayout logo={false} />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'terms', element: <Terms /> },
        // { path: 'ipsfupcomingevents', element: <TvScreens /> },
        // { path: 'delay', element: <DelayList /> },
        // { path: 'allevents', element: <AllEvents /> },
        // { path: 'leaderboard', element: <LeaderBoard /> },
        // { path: 'ipsfleaderboard', element: <TvLeaderBoard /> },
        // { path: 'leaderboardlist', element: <LeaderBoardList /> },
        // { path: 'upcomingeventlist', element: <UpcomingEventslist /> },
        // { path: 'winnerslist', element: <WinnersList /> },
        // { path: 'progressingeventlist', element: <ProgressingEventList /> },
        // { path: 'upcomingevents', element: <UpcomingEvents /> },
        // { path: 'progressingevents', element: <ProgressingEvents /> },
        // { path: 'tvprogressingevents', element: <TvProgressingEvents /> },
        // { path: 'ipsfwinners', element: <TvWinner /> },
        // { path: 'winnersexp', element: <WinnerExp /> },
        // { path: 'winners', element: <Winners /> },
        { path: 'passwordemail', element: <PasswordEmail /> },
        { path: 'requestpasswordemail', element: <PasswordReq /> },
        { path: 'confirmpassword', element: <ConfirmPassword /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'events', element: <Events /> },
        { path: 'edit-events', element: <EditEvent /> },
        { path: 'eventpoints', element: <EventPoints /> }
      ]
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { path: '', element: <LandingPage /> },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //     {
    //       path: 'components',
    //       children: [
    //         { path: '', element: <ComponentsOverview /> },
    //       children: [
    //         { path: '/', element: <ComponentsOverview /> },
    //         { path: 'icons', element: <Icons /> },
    //         // MATERIAL UI
    //         { path: 'accordion', element: <Accordion /> },
    //         { path: 'alert', element: <Alert /> },
    //         { path: 'autocomplete', element: <Autocomplete /> },
    //         { path: 'avatar', element: <Avatar /> },
    //         { path: 'badge', element: <Badge /> },
    //         { path: 'breadcrumbs', element: <Breadcrumb /> },
    //         { path: 'buttons', element: <Buttons /> },
    //         { path: 'checkbox', element: <Checkbox /> },
    //         { path: 'chip', element: <Chip /> },
    //         { path: 'dialog', element: <Dialog /> },
    //         { path: 'label', element: <Label /> },
    //         { path: 'list', element: <List /> },
    //         { path: 'menu', element: <Menu /> },
    //         { path: 'pagination', element: <Pagination /> },
    //         { path: 'pickers', element: <Pickers /> },
    //         { path: 'popover', element: <Popover /> },
    //         { path: 'progress', element: <Progress /> },
    //         { path: 'radio-button', element: <RadioButtons /> },
    //         { path: 'rating', element: <Rating /> },
    //         { path: 'slider', element: <Slider /> },
    //         { path: 'snackbar', element: <Snackbar /> },
    //         { path: 'stepper', element: <Stepper /> },
    //         { path: 'switch', element: <Switches /> },
    //         { path: 'table', element: <Table /> },
    //         { path: 'tabs', element: <Tabs /> },
    //         { path: 'textfield', element: <Textfield /> },
    //         { path: 'timeline', element: <Timeline /> },
    //         { path: 'tooltip', element: <Tooltip /> },
    //         { path: 'transfer-list', element: <TransferList /> },
    //         { path: 'tree-view', element: <TreeView /> },
    //         { path: 'data-grid', element: <DataGrid /> },
    //         // EXTRA COMPONENTS
    //         { path: 'chart', element: <Charts /> },
    //         { path: 'map', element: <Map /> },
    //         { path: 'editor', element: <Editor /> },
    //         { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
    //         { path: 'upload', element: <Upload /> },
    //         { path: 'carousel', element: <Carousel /> },
    //         { path: 'multi-language', element: <MultiLanguage /> },
    //         { path: 'animate', element: <Animate /> },
    //         { path: 'mega-menu', element: <MegaMenu /> },
    //         { path: 'form-validation', element: <FormValidation /> }
    //       ]
    //     }
    //   ]
    // },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const RegisterSuccess = Loadable(lazy(() => import('../pages/authentication/RegisterSuccess')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/Reports')));
const CheckInPage = Loadable(lazy(() => import('../pages/dashboard/CheckInPage')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const WinnerExp = Loadable(lazy(() => import('../pages/dashboard/WinnerExp')));
const WinnerListExp = Loadable(lazy(() => import('../pages/dashboard/WinnerListExp')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const FamilyList = Loadable(lazy(() => import('../pages/dashboard/family/FamilyList')));
const FamilyCreate = Loadable(lazy(() => import('../pages/dashboard/family/FamilyCreate')));
const ParishParticipantList = Loadable(lazy(() => import('../pages/dashboard/ParishParticipantList')));
const ParishList = Loadable(lazy(() => import('../pages/dashboard/ParishList')));
const ParishCreate = Loadable(lazy(() => import('../pages/dashboard/ParishCreate')));
const FamilyMemberList = Loadable(lazy(() => import('../pages/dashboard/FamilyMemberList')));
const FamilyMemberCreate = Loadable(lazy(() => import('../pages/dashboard/FamilyMemberCreate')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const AllEvents = Loadable(lazy(() => import('../pages/dashboard/AllEvents')));
const EventList = Loadable(lazy(() => import('../pages/dashboard/EventList')));
const FamilyAccountSchedule = Loadable(lazy(() => import('../pages/dashboard/FamilyAccountSchedule')));
const EventCreate = Loadable(lazy(() => import('../pages/dashboard/EventCreate')));
const EventConfigList = Loadable(lazy(() => import('../pages/dashboard/EventConfigList')));
const EventConfigCreate = Loadable(lazy(() => import('../pages/dashboard/EventConfigCreate')));
const EventScheduleCreate = Loadable(lazy(() => import('../pages/dashboard/EventScheduleCreate')));
const ParticipantEventList = Loadable(lazy(() => import('../pages/dashboard/ParticipantEventList')));
const ParticipantEventCreate = Loadable(lazy(() => import('../pages/dashboard/ParticipantEventCreate')));
const ParticipantEventReg = Loadable(lazy(() => import('../pages/dashboard/ParticipantEventReg')));
const ParticipantRegList = Loadable(lazy(() => import('../pages/dashboard/ParticipantRegList')));
const PaymentTransactions = Loadable(lazy(() => import('../pages/dashboard/PaymentTransactions')));
const RegParticipantList = Loadable(lazy(() => import('../pages/dashboard/RegParticipantList')));
// const EventOperationsCreate = Loadable(lazy(() => import('../pages/dashboard/EventOperationsCreate')));
const EventOperationsList = Loadable(lazy(() => import('../pages/dashboard/EventOperationsList')));
const ScheduleEditList = Loadable(lazy(() => import('../pages/dashboard/ScheduleEditList')));
const LiveStreamingList = Loadable(lazy(() => import('../pages/dashboard/LiveStreamingList')));
const ParticipantRegistrationPayment = Loadable(
  lazy(() => import('../pages/participant/ParticipantRegistrationPayment'))
);
const ParticipantPayments = Loadable(lazy(() => import('../pages/participant/ParticipantPayments')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Events = Loadable(lazy(() => import('../pages/Events')));
const EditEvent = Loadable(lazy(() => import('../pages/EditEvent')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Terms = Loadable(lazy(() => import('../pages/Terms')));
const LeaderBoard = Loadable(lazy(() => import('../pages/LeaderBoard')));
const TvLeaderBoard = Loadable(lazy(() => import('../pages/TvLeaderBoard')));
const LeaderBoardList = Loadable(lazy(() => import('../pages/LeaderBoardList')));
const TvScreens = Loadable(lazy(() => import('../pages/TvScreens')));
const DelayList = Loadable(lazy(() => import('../pages/DelayList')));
const UpcomingEventslist = Loadable(lazy(() => import('../pages/UpcomingEventslist')));
const WinnersList = Loadable(lazy(() => import('../pages/WinnersList')));
const ProgressingEventList = Loadable(lazy(() => import('../pages/ProgressingEventList')));
const TvWinner = Loadable(lazy(() => import('../pages/TvWinner')));
const TvProgressingEvents = Loadable(lazy(() => import('../pages/TvProgressingEvents')));
const UpcomingEvents = Loadable(lazy(() => import('../pages/UpcomingEvents')));
const ProgressingEvents = Loadable(lazy(() => import('../pages/ProgressingEvents')));
const Winners = Loadable(lazy(() => import('../pages/Winners')));
const EventPoints = Loadable(lazy(() => import('../pages/EventPoints')));
const PasswordEmail = Loadable(lazy(() => import('../pages/PasswordEmail')));
const PasswordReq = Loadable(lazy(() => import('../pages/PasswordReq')));
const ConfirmPassword = Loadable(lazy(() => import('../pages/ConfirmPassword')));
// Components
const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColors')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
//
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const FormValidation = Loadable(lazy(() => import('../pages/components-overview/extra/form-validation')));
