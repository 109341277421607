// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  registerSuccess: path(ROOTS_AUTH, '/register-success')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  terms: '/terms',
  ipsfupcomingevents: '/ipsfupcomingevents',
  delay: '/delay',
  upcomingeventlist: '/upcomingeventlist',
  winnerslist: '/winnerslist',
  progressingeventlist: '/progressingeventlist',
  ipsfwinners: '/ipsfwinners',
  winnersexp: '/winnersexp',
  tvprogressingevents: '/tvprogressingevents',
  upcomingevents: '/upcomingevents',
  progressingevents: '/progressingevents',
  leaderboard: '/leaderboard',
  ipsfleaderboard: '/ipsfleaderboard',
  leaderboardlist: '/leaderboardlist',
  winners: '/winners',
  allevents: '/allevents',
  passwordemail: '/passwordemail',
  requestpasswordemail: '/requestpasswordemail',
  confirmpassword: '/confirmpassword',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  participant: {
    root: path(ROOTS_DASHBOARD, '/participant'),
    list: path(ROOTS_DASHBOARD, '/participant/list'),
    newUser: path(ROOTS_DASHBOARD, '/participant/new'),
    checkIn: path(ROOTS_DASHBOARD, '/check-in')
  },
  parish: {
    root: path(ROOTS_DASHBOARD, '/parish'),
    list: path(ROOTS_DASHBOARD, '/parish/list'),
    participantList: path(ROOTS_DASHBOARD, '/parish/participant-list'),
    newUser: path(ROOTS_DASHBOARD, '/parish/new')
  },
  family: {
    root: path(ROOTS_DASHBOARD, '/family'),
    list: path(ROOTS_DASHBOARD, '/family/list'),
    newUser: path(ROOTS_DASHBOARD, '/family/new')
  },
  familymember: {
    root: path(ROOTS_DASHBOARD, '/familymember'),
    list: path(ROOTS_DASHBOARD, '/familymember/list'),
    newUser: path(ROOTS_DASHBOARD, '/familymember/new')
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    parish: path(ROOTS_DASHBOARD, '/user/parishlist'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    payment: path(ROOTS_DASHBOARD, '/user/payment'),
    payments: path(ROOTS_DASHBOARD, '/user/payments'),
    transactions: path(ROOTS_DASHBOARD, '/user/payment-transactions')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  event: {
    root: path(ROOTS_DASHBOARD, '/event'),
    list: path(ROOTS_DASHBOARD, '/event/list'),
    newUser: path(ROOTS_DASHBOARD, '/event/new')
  },
  familyaccountschedule: {
    root: path(ROOTS_DASHBOARD, '/familyaccountschedule'),
    list: path(ROOTS_DASHBOARD, '/familyaccountschedule/list')
  },
  eventconfig: {
    root: path(ROOTS_DASHBOARD, '/eventconfig'),
    list: path(ROOTS_DASHBOARD, '/eventconfig/list'),
    newUser: path(ROOTS_DASHBOARD, '/eventconfig/new')
  },
  participantevent: {
    root: path(ROOTS_DASHBOARD, '/participantevent'),
    list: path(ROOTS_DASHBOARD, '/participantevent/list'),
    finalList: path(ROOTS_DASHBOARD, '/participantevent/final-list'),
    EveRegList: path(ROOTS_DASHBOARD, '/participantevent/evereg-list'),
    newUser: path(ROOTS_DASHBOARD, '/participantevent/new'),
    reg: path(ROOTS_DASHBOARD, '/participantevent/reg'),
    reglist: path(ROOTS_DASHBOARD, '/participantevent/reg-list')
  },
  eventoperations: {
    root: path(ROOTS_DASHBOARD, '/eventoperations'),
    list: path(ROOTS_DASHBOARD, '/eventoperations/list'),
    slist: path(ROOTS_DASHBOARD, '/scheduleoperations/list'),
    newUser: path(ROOTS_DASHBOARD, '/eventoperations/new')
  },
  scheduleoperations: {
    root: path(ROOTS_DASHBOARD, '/scheduleoperations'),
    list: path(ROOTS_DASHBOARD, '/scheduleoperations/list'),
    newUser: path(ROOTS_DASHBOARD, '/scheduleoperations/new')
  },
  livestreaming: {
    root: path(ROOTS_DASHBOARD, '/livestreaming'),
    list: path(ROOTS_DASHBOARD, '/livestreaming/list'),
    newUser: path(ROOTS_DASHBOARD, '/livestreaming/new')
  },
  eventschedule: {
    newUser: path(ROOTS_DASHBOARD, '/eventschedule/new')
  },
  reports: path(ROOTS_DASHBOARD, '/reports')
};

export const PATH_DOCS = '';
