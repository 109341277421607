import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { ThemeProvider, createTheme } from '@mui/material/styles';
// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          //   backgroundImage: 'linear-gradient(167deg, #003D64 36%, #79024D 68%)'
        }
      }
    }
  }
});

// ----------------------------------------------------------------------

export default function NoLogoLayout() {
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
      <style>{`
        thead th {
            border-left: 1px solid rgb(255 255 255) !important;
            border-right: 1px solid rgb(255 255 255) !important;
            border-bottom: 1px solid rgb(255 255 255) !important;
        }
        body {
            // background: transparent;
        }
        th {
            font-size: 1.6rem !important;
        }
        td {
            font-size: 1.2rem !important;
        }
        `}</style>
    </ThemeProvider>
  );
}
