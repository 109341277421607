import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
  useMediaQuery,
  CardMedia
} from '@material-ui/core';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const SectionTop = {
  paddingTop: 8,
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center'
};

const SectionBottom = {
  paddingTop: 8
};

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
    padding: theme.spacing(6, 0, 12)
  }
}));
// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  const matches = useMediaQuery('(max-width:899px)');

  return (
    <RootStyle title="Login | IPSF 2024">
      {1 === 0 && (
        <AuthLayout>
          Don’t have a family account? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
            Family Account Sign Up
          </Link>
        </AuthLayout>
      )}

      <MHidden width="mdDown">
        <SectionStyle sx={{ backgroundColor: 'initial' }}>
          {/* <SectionStyle style={{ backgroundColor: '#51d0fd' }}> */}
          {/* <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome to IPSF 2024
          </Typography> */}
          <img src="/static/illustrations/Poster-24.jpg" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }} style={SectionBottom}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" gutterBottom>
                Sign in to Inter Parish Sports Festival 2024
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                St. Joseph Syro Malabar Catholic Forane Church Houston, Texas.
              </Typography>
            </Box>

            {/* <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}

          {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>admin@ipsfhouston.com</strong> / password :<strong>&nbsp;12345678</strong>
          </Alert> */}

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )}
          {matches && 1 > 2 && (
            <Grid container spacing={2} alignItems="center" style={SectionTop}>
              <Grid item xs={3}>
                <Card>
                  <CardMedia
                    component="img"
                    height="auto"
                    width="50%"
                    image="/static/brand/IPSF-LOGO-2024.png"
                    alt="green iguana"
                  />
                </Card>
              </Grid>
              {/* <Grid item xs={3}>
                <Card>
                  <CardMedia
                    component="img"
                    height="auto"
                    width="50%"
                    image="/static/illustrations/IPSF-Logo-200-200.jpg"
                    alt="green iguana"
                  />
                </Card>
              </Grid> */}
            </Grid>
          )}
          {1 === 0 && (
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have a family account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Family Account Sign Up
                </Link>
              </Typography>
            </MHidden>
          )}
          {matches && (
            <Card sx={{ mt: 2, backgroundColor: '#74b7d7' }}>
              <CardMedia
                component="img"
                height="auto"
                width="100%"
                image="/static/illustrations/IPSF-Sponsors.png"
                alt="green iguana"
              />
            </Card>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
