import axios from 'axios';

// ----------------------------------------------------------------------

// const axiosInstance = axios.create();

const axiosInstance = axios.create({
  // prod
  baseURL: 'https://d36recl8f4.execute-api.us-east-1.amazonaws.com/production/',
  // dev
  // baseURL: 'https://ag16r804ni.execute-api.us-east-1.amazonaws.com/dev/',
  // timeout: 1000,
  // 'X-Amz-Security-Token - cognito token
  // ' - pass role
  headers: { 'X-Amz-Security-Token': localStorage.getItem('userEmail') }
  // headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': true }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === '403' &&
      typeof localStorage.getItem('userEmail') === 'undefined' &&
      !localStorage.getItem('userEmail')
    ) {
      window.location.reload();
    } else {
      Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
  }
);

export default axiosInstance;
