import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
// components
import Logo from '../components/Logo';
//
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------
const LogoContainer = styled(Box)(({ theme }) => ({
  // width: '70px',
  // height: '70px',
  width: 'auto',
  maxWidth: '190px',
  height: 'auto',
  backgroundImage: 'linear-gradient(167deg, #003D64 36%, #79024D 68%)',
  border: '1px solid #583661',
  // Adding media querry..
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  backgroundColor: 'initial !important',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(5, 5, 0, 5)
  },
  [theme.breakpoints.down('sm')]: {
    position: 'initial'
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <LogoContainer>
          <img src="/static/brand/IPSF-LOGO-2024.png" alt="IPSF 2024" />
        </LogoContainer>
      </RouterLink>

      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
