import { Icon } from '@iconify/react';
import tvFill from '@iconify/icons-eva/tv-fill';
import cameraOutline from '@iconify/icons-eva/camera-outline';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill, href: 'https://m.facebook.com/ipsfhouston' },
  { name: 'Instagram', icon: cameraOutline, href: 'https://www.instagram.com/ipsfhouston/' },
  { name: 'YouTube', icon: tvFill, href: 'https://m.youtube.com/@IPSFHouston?sub_confirmation=1' }
];

const LINKS = [
  {
    headline: 'Menu',
    children: [
      { name: 'About Us', href: 'https://ipsfhouston.com/about/' },
      { name: 'Contact Us', href: 'https://ipsfhouston.com/contact-us/' }
    ]
  },
  {
    headline: 'Other',
    children: [
      { name: 'IPSF 2024 FAQ', href: 'https://ipsfhouston.com/about/' },
      // { name: 'IPSF 2024 Guidelines', href: 'https://ipsfhouston.com/about/' },
      { name: 'Home', href: 'https://ipsfhouston.com' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'info@ipsfhouston.com', href: 'mailto:info@ipsfhouston.com' },
      { name: 'Social Media', href: 'https://link.ipsfhouston.com/Social-Media' },
      {
        name: 'Fort Bend County Epicenter',
        // name: 'Houston, Texas/Oklahoma Region ☨ Diocese of Chicago, United States',
        href: 'https://maps.app.goo.gl/3Ceo3E2Dz3JkSTzQA'
      }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              St. Joseph Syro Malabar Catholic Forane Church Houston, Texas is proud to host IPSF 2024 from August 1-4.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Link target="_blank" href={social.href}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </Link>
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        href={link.href}
                        target="_blank"
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        // component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2024 IPSF is powered by{' '}
          <Link target="_blank" href="http://stjosephhouston.org/">
            St. Joseph Syro Malabar Catholic Forane Church Houston, TX. &nbsp;
          </Link>
          All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
